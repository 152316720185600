var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header-background"},[(_vm.swiperList.length > 0)?_c('el-carousel',{attrs:{"height":_vm.carouselHeight + 'px'}},_vm._l((_vm.swiperList),function(item){return _c('el-carousel-item',{key:item.id,staticClass:"swiper-item",style:({ backgroundImage: ("url(" + (item.picPath) + ")") })})}),1):_c('img',{attrs:{"src":"https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625643239478.png","alt":""}})],1),_c('div',{staticClass:"section"},[_c('div',{staticClass:"company-profile"},[(_vm.companyProfile)?_c('div',{staticClass:"company-profile-title"},[_vm._v(" 公司简介 ")]):_vm._e(),(_vm.companyProfile)?_c('div',{staticClass:"company-profile-desc"},[_vm._v(" "+_vm._s(_vm.companyProfile)+" ")]):_vm._e(),_vm._m(0),_vm._m(1)]),(_vm.latestNewsVisible)?_c('div',{staticClass:"latest-news"},[_c('div',{ref:"prevBtn",staticClass:"prev-btn",on:{"click":function($event){return _vm.imgMovePrev(
                        'latestNewsImgs',
                        'latestNewsImgsContainer',
                        'prevBtn',
                        'nextBtn',
                        408,
                        3
                    )}}},[_c('img',{attrs:{"src":"https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png","alt":""}})]),_c('div',{ref:"nextBtn",staticClass:"next-btn",on:{"click":function($event){return _vm.imgMoveNext(
                        'latestNewsImgs',
                        'latestNewsImgsContainer',
                        'prevBtn',
                        'nextBtn',
                        408,
                        3
                    )}}},[_c('img',{attrs:{"src":"https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png","alt":""}})]),_c('div',{staticClass:"latest-news-title"},[_vm._v("最新资讯")]),_c('div',{ref:"latestNewsImgsContainer",staticClass:"latest-news-imgs-container"},[_c('div',{ref:"latestNewsImgs",staticClass:"latest-news-imgs"},_vm._l((_vm.latestNewsList),function(item){return _c('div',{key:item.id,staticClass:"latest-news-img",style:({
                            backgroundImage: 'url(' + item.picPath + ')',
                        }),on:{"click":function($event){return _vm.jumpLatestNews(item)}}},[_c('div',{staticClass:"latest-news-img-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"latest-news-img-content"},[_vm._v(" "+_vm._s(item.content)+" ")])])}),0)])]):_vm._e(),(_vm.tourArticleVisible)?_c('div',{staticClass:"travel-artcle"},[_c('div',{staticClass:"travel-artcle-title"},[_vm._v("旅行文章")]),_c('div',{staticClass:"travel-artcle-imgs"},[_c('div',{staticClass:"travel-artcle-imgs-box"},[_c('div',{staticClass:"travel-artcle-left"},[_c('div',{staticClass:"date"},[_c('div',{staticClass:"date-left"},[_vm._v(_vm._s(_vm.date))]),_c('div',{staticClass:"date-right"},[_vm._v(" "+_vm._s(_vm.monthList[_vm.month])+". ")])]),_c('div',{staticClass:"travel-artcle-left-bottom"},[_c('div',{staticClass:"travel-artcle-left-title"},[_vm._v(" "+_vm._s(_vm.site.name)+" ")]),_c('div',{staticClass:"travel-artcle-left-content"},[_vm._v(" "+_vm._s(_vm.site.description)+" ")])])]),_c('div',{ref:"travelArtcleRightContainer",staticClass:"travel-artcle-right-container"},[_c('div',{ref:"travelArtcleRight",staticClass:"travel-artcle-right"},_vm._l((_vm.tourArticleList),function(item){return _c('div',{key:item.id,staticClass:"travel-artcle-right-img",on:{"click":function($event){return _vm.jumpLatestNews(item)}}},[_c('div',{staticClass:"travel-artcle-right-img-top",style:({
                                        backgroundImage:
                                            'url(' + item.picPath + ')',
                                    })}),_c('div',{staticClass:"travel-artcle-right-img-bottom"},[_c('div',{staticClass:"travel-artcle-right-img-bottom-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"travel-artcle-right-img-bottom-content"},[_vm._v(" "+_vm._s(item.content)+" ")])])])}),0)])]),_c('div',{ref:"prevBtn1",staticClass:"prev-btn",on:{"click":function($event){return _vm.imgMovePrev(
                            'travelArtcleRight',
                            'travelArtcleRightContainer',
                            'prevBtn1',
                            'nextBtn1',
                            236,
                            4
                        )}}},[_c('img',{attrs:{"src":"https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png","alt":""}})]),_c('div',{ref:"nextBtn1",staticClass:"next-btn",on:{"click":function($event){return _vm.imgMoveNext(
                            'travelArtcleRight',
                            'travelArtcleRightContainer',
                            'prevBtn1',
                            'nextBtn1',
                            236,
                            4
                        )}}},[_c('img',{attrs:{"src":"https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625709841281.png","alt":""}})])])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"company-business"},[_c('li',[_c('img',{attrs:{"src":"https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625651470982.png","alt":""}}),_c('span',[_vm._v("线上社交")])]),_c('li',[_c('img',{attrs:{"src":"https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625651492057.png","alt":""}}),_c('span',[_vm._v("旅行产品")])]),_c('li',[_c('img',{attrs:{"src":"https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625651516641.png","alt":""}}),_c('span',[_vm._v("软件开发")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about-us"},[_c('div',{staticClass:"about-us-left"},[_c('div',{staticClass:"about-us-left-title"},[_vm._v("关于我们")]),_c('div',{staticClass:"about-us-left-content"},[_c('span',[_vm._v(" 名称：墨鱼旅行")]),_c('span',[_vm._v(" 亮点：和有趣的人组队看世界 ")]),_c('span',[_vm._v(" 价值：为全球驴友匹配契合的队友 ")]),_c('span',[_vm._v(" 使命：打造全球最大的旅行社交平台 ")]),_c('span',[_vm._v(" 愿景：不止于旅行跟世界交朋友")]),_c('span',[_vm._v(" 特色：万能的队长、有趣的驴友、创意的行程")])])])])}]

export { render, staticRenderFns }